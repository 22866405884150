.ReactModal__Overlay {
  align-items: flex-end;
}

.ReactModal__Content {
  background: #1f1f1f;
  border: none !important;
  width: 1096px !important;
  height: 752px !important;

  opacity: 0;
  transform: translateY(300px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(300px);
}

.ConfirmModal__Content {
  min-width: 366px;
  max-width: 750px;
  height: 150px;
  background: #1f1f1f !important;
  border-radius: 20px;
  padding: 16px;
  outline: none;
  position: relative;
  scale: 0%;
  opacity: 0;
  -webkit-tap-highlight-color: transparent;
  transition: all 300ms ease-in-out;
}

.ConfirmModal__Content--before-close {
  opacity: 0;
  scale: 0%;
  transition: all 300ms ease-in-out;
}

.ConfirmModal__Content--after-open {
  opacity: 1;
  scale: 100%;
  transition: all 300ms ease-in-out;
}

.MiddleModal__Content {
  min-width: 366px;
  max-width: 750px;
  height: 282px;
  background: #1f1f1f;
  padding: 16px;
  outline: none;
  position: relative;
  transition: all 300ms ease-in-out;
}

.MiddleModal__Overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000060;
  z-index: 9999 !important;

  transition: opacity 300ms;
}

.EditClass__Content {
  width: 600px;
  height: 700px;
  background: #242b2f !important;
  border-radius: 20px;
  padding: 20px;
  overflow: auto !important;
}

.EditClass__Overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000060;
  z-index: 15 !important;

  transition: opacity 300ms;
}

.MiddleModal__Content--before-close {
  opacity: 0;
  scale: 0%;
  transition: all 300ms ease-in-out;
}

.MiddleModal__Content--after-open {
  opacity: 1;
  scale: 100%;
  transition: all 300ms ease-in-out;
}

.VolatilityModal__Content {
  width: auto;
  height: 700px;
  background: #242b2f !important;
  border-radius: 20px;
  padding: 20px;
  overflow: auto !important;
}

.GameRulesModal__Content {
  width: 400px;
  height: 700px;
  background: #242b2f !important;
  border-radius: 20px;
  padding: 20px;
  overflow: auto !important;
}

.PositionGridsModal__Content {
  width: 600px;
  height: 700px;
  background: #242b2f !important;
  border-radius: 20px;
  padding: 20px;
  overflow: auto !important;
}

.VolatilityModal__Overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000060;
}

.NewsHistoryModal__Overlay {
  position: fixed;
  z-index: 9999 !important;
  inset: 0;
  display: flex;
  align-items: flex-end;
}

.NewsHistoryModal__Content {
  background: #262f35;
  border: none !important;
  width: 1204px !important;
  height: 850px !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: 0;
  transform: translateY(300px);
  transition: all 300ms ease-in-out;
}

.NewsHistoryModal__Content--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.NewsHistoryModal__Content--before-close {
  opacity: 0;
  transform: translateY(300px);
}

.ScenarioModal__Content {
  width: max-content !important;
  height: max-content !important;
  padding: 1rem 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100005 !important;
  border: none !important;
  border-radius: 0.75rem;
  background: #262f35;

  opacity: 0;
  transform: translateY(300px);
  transition: all 300ms ease-in-out;
}

.ScenarioModal__Content--after-open {
  opacity: 1;
  transform: translateY(0px);
  z-index: 100005 !important;
}

.ScenarioModal__Content--before-close {
  opacity: 0;
  transform: translateY(300px);
  z-index: 100005 !important;
}

.AdminScenarioModal__Content {
  width: 90vw !important;
  height: 80vh !important;
  padding: 1rem 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100005 !important;
  border: none !important;
  border-radius: 0.75rem;
  background: #262f35;
  overflow-y: auto;

  opacity: 0;
  transform: translateY(300px);
  scale: 0%;
  transition: all 300ms ease-in-out;
}

.AdminScenarioModal__Content--after-open {
  opacity: 1;
  transform: translateY(0px);
  scale: 100%;
  z-index: 100005 !important;
}

.AdminScenarioModal__Content--before-close {
  opacity: 0;
  transform: translateY(300px);
  scale: 0%;
  z-index: 100005 !important;
}

.WarningModal__Content {
  min-width: 366px;
  max-width: 366px;
  height: 233px;
  background: #242b2f !important;
  border-radius: 20px;
  padding: 12px;
  outline: none;
  position: relative;

  -webkit-tap-highlight-color: transparent;
}

.WarningModal__Overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000060;
  z-index: 9999 !important;

  transition: opacity 300ms;
}

.WarningModal__Content--before-close {
  opacity: 0;
  transition: opacity 300ms;
}
